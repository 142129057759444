// https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/angular/dropdown
import Image from 'next/image';
import Link from 'next/link';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
	FiUserPlus,
	FiPhoneForwarded,
	FiCalendar,
	FiThumbsUp,
	FiChevronDown,
	FiChevronUp
} from 'react-icons/fi';
import {
	Footer,
	MainHeader,
	MainWrapper,
	PageFullWrapper,
	HomeForm,
	Stars,
	SearchType
} from 'components/front';
import {
	CommonServerSideProps,
	getCommonServerSideProps
} from 'api-common/common-server-side-props';
import { SessionProvider } from 'common/session/session-context';
import { ProfessionDto } from 'entities/profession.dto';
import { createSearchResultUrl, createPrettySearchResultUrl } from './specjalisci/[[...slugPath]]';
import { createPrettySearchResultUrlForPlace, createSearchResultUrlForPlace } from './placowki/[[...slugPath]]';
import { createPrettySearchResultUrlForEvents, createSearchResultUrlForEvents } from './wydarzenia/[[...slugPath]]';
import { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import Head from "next/head";

export const createSearchResultUrlByType = (
	specializationFilter: string,
	cityFilter?: string,
	onlineFilter?: boolean,
	redirect?: boolean,
	searchType?: SearchType
) => {
	if (searchType === 'specialist') {
		return createPrettySearchResultUrl(
			specializationFilter,
			cityFilter,
			onlineFilter,
			redirect
		);
	} else if (searchType === 'place') {
		return createPrettySearchResultUrlForPlace(
			specializationFilter,
			cityFilter,
			onlineFilter,
			redirect
		);
	} else if (searchType === 'event') {
		return createPrettySearchResultUrlForEvents(
      specializationFilter,
      cityFilter,
      onlineFilter
    );
	}

	return createPrettySearchResultUrl(
		specializationFilter,
		cityFilter,
		onlineFilter,
		redirect
	);
};

export const getServerSideProps = getCommonServerSideProps;

const PROFESSIONS_AND_CITIES_QUERY = gql`
	query HomeQuery($where: professions_bool_exp!) {
		professions(where: $where, order_by: { homepage_order: asc_nulls_last }) {
			id
			name
			slug
			type
			parent_id
		}
		addressess_aggregate(
			distinct_on: city
			limit: 30
			where: { is_online: { _eq: false } }
		) {
			nodes {
				city
			}
		}
		random_specialists_v3 {
			id
			avatar_url
			shown_name
			profession {
				name
			}
			opinions_aggregate {
				aggregate {
					avg {
						rating
					}
				}
			}
		}
	}
`;

const RANDOM_SPECIALISTS = gql`
	query HomeQuery {
		random_specialists_v3 {
			id
			avatar_url
			shown_name
			profession {
				name
			}
			opinions_aggregate {
				aggregate {
					avg {
						rating
					}
				}
			}
		}
	}
`;

const PROFESSIONS_ID = 'professions';

const homePage: React.FC<CommonServerSideProps> = ({ userSession }) => {
	const [
		getProfessionsAndCitiesForSearchType,
		{
			loading: professionsAndCitiesLoading,
			error: professionsAndCitiesError,
			data: professionsAndCitiesData
		}
	] = useLazyQuery(PROFESSIONS_AND_CITIES_QUERY);

	const {
		data: specialistsData,
		error: specialistsErrors,
		loading: specialistsLoading
	} = useQuery(RANDOM_SPECIALISTS);

	const [specializationsExpanded, setSpecializationsExpanded] = useState(false);

	const toggleSpecializations = () =>
		setSpecializationsExpanded(!specializationsExpanded);

	const onSearchTypeChange = (searchType: SearchType) => {
		let where: any = {
			parent_id: { _is_null: true }
		};

		if (searchType !== 'event') {
			where = {
				...where,
				type: { _eq: searchType }
			};
		}

		getProfessionsAndCitiesForSearchType({
			variables: {
				where
			}
		});
	};

	const [online, setOnline] = useState(false);

	const onOnlineChange = (isOnline: boolean) => {
		setOnline(isOnline);
	};

	return (
		<>
			<Head>
        <title>Najlepsi specjaliści - znany.pl</title>
        <meta
            name="keywords"
            content="specjaliści"
        />
        <meta
            name="description"
            content="Specjaliści dostępni na portalu znany.pl"
        />
    	</Head>
		<SessionProvider userSession={userSession}>
			<MainWrapper>
				<MainHeader withBg={false} home={true} withGradient={false} />

				<PageFullWrapper noPadding bg={`bg-white`}>
					<div className='relative px-4 md:px-0 md:-mt-16 pt-8'>
						<Image
							className={`z-10`}
							layout='fill'
							objectFit='cover'
							src={'/bg.svg'}
							priority
						/>
						<div
							className={`mx-auto relative md:w-11/12 xl:w-10/12
               grid grid-cols-8`}
						>
							<div className='z-20 col-span-8 md:col-span-4 text-4xl text-white mt-10 sm:mt-4 md:mt-28 xl:mt-52 pb-3'>
								<h1 className='text-white'>Znajdź znanego specjalistę</h1>
								<h2 className='text-base md:text-lg pt-2 text-white'>
									i umów wizytę na miejscu lub online!
								</h2>
							</div>
							{/* People */}
							<div className='col-span-4 row-span-3 relative z-10 self-end xl:h-5/6 md:h-5/6 origin-bottom'>
								<Image
									className={`z-10 border top-10`}
									layout='fill'
									objectFit='contain'
									src={'/people-home.png'}
								/>
							</div>

							<div className='z-40 col-span-8 md:col-span-4 bg-white bg-opacity-20 rounded-lg p-4 mb-16 md:-mr-48 '>
								<HomeForm
									home
									onSearchTypeChange={onSearchTypeChange}
									onOnlineChange={onOnlineChange}
									data={professionsAndCitiesData}
									createSearchResultUrl={createSearchResultUrlByType}
								/>
							</div>
						</div>
					</div>

					<div
						className={`
            md:w-11/12 xl:w-10/12
            md:-mt-12  xl:-mt-5 
            bg-gray-100
            font-medium
              shadow rounded-lg
              relative mx-auto z-10
              text-center
               md:text-left gap-2 flex-wrap p-8 text-gray-500 text-sm
          `}
					>
						<div>
							{!specializationsExpanded ? (
								<TruncateMarkup
									tokenize='words'
									ellipsis={
										<span
											onClick={toggleSpecializations}
											className='ml-3 cursor-pointer inline-flex hover:underline text-blue-700'
										>
											więcej <FiChevronDown size={20} />
										</span>
									}
									lines={4}
								>
									<div>
										{professionsAndCitiesData?.professions.length > 0 &&
											professionsAndCitiesData?.professions.map(
												(el: ProfessionDto, i: number) => (
													<>
														{i > 0 && <span className='mx-2'>&#183;</span>}
														<a
															href={createSearchResultUrlByType(
																el.name,
																'',
																online,
                                false,
                                el.type
															)}
															key={el.slug}
														>
															<span className={`inline-block font-medium my-1`}>
																{el.name.split(' ')[0] +
																	String.fromCharCode(160) +
																	el.name.split(' ').slice(1).join(' ')}
															</span>
														</a>
													</>
												)
											)}
									</div>
								</TruncateMarkup>
							) : (
								<div>
									{professionsAndCitiesData?.professions.length &&
										professionsAndCitiesData?.professions.map(
											(el: ProfessionDto, i: number) => (
												<>
													{i > 0 && <span className='mx-2'>&#183;</span>}
													<Link
														href={createSearchResultUrlByType(
																el.name,
																'',
																online,
                                false,
                                el.type
															)}
														key={el.slug}
													>
														<a className={`inline-block font-medium my-1`}>
															{el.name}
														</a>
													</Link>
												</>
											)
										)}
									<span
										onClick={toggleSpecializations}
										className='ml-3 cursor-pointer inline-flex hover:underline text-blue-700'
									>
										mniej <FiChevronUp size={20} />
									</span>
								</div>
							)}
						</div>
					</div>

					<div className="lg:hidden pt-4 pb-2">
						<p className="text-center px-5 py-5"><strong className="text-blue-600">Uwaga specjalisto!!!</strong> Nie zwlekaj, zarejestruj się teraz, a otrzymasz <strong className="text-blue-600">darmowe konto PREMIUM na 12 miesięcy</strong>. Szczegóły w zakładce <Link href="/pricing"><strong className="text-blue-600" style={{cursor: 'pointer'}}>CENNIK</strong></Link>.</p>
					</div>

					<div
						className={`
              px-5 md:px-0
              md:w-11/12 xl:w-10/12
              grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 m-8 text-center mx-auto items-start
              flex-start gap-8 relative z-20 
          `}
					>
						<p className='flex items-start flex-col content-start justify-start'>
							<div className='font-bold flex flex-row pb-4 text-left'>
								<div className='text-2xl text-yellow-600 pr-2'>
									<FiUserPlus />
								</div>
								<div className='font-bold'>Znajdź specjalistę</div>
							</div>
							<p className='text-left'>
								Wybierz spośród wielu znanych specjalistów. Przeglądaj opinie
								innych użytkowników
							</p>
						</p>

						<p className='flex items-start flex-col content-start justify-start'>
							<div className='font-bold flex flex-row pb-4 text-left'>
								<div className='text-2xl text-yellow-600 pr-2'>
									<FiCalendar />
								</div>
								<div className='font-bold'>Zarezerwuj usługę</div>
							</div>
							<p className='text-left'>
								Wybierz odpowiadający Ci termin, potwierdź i usługa umówiona
							</p>
						</p>

						<p className='flex items-start flex-col content-start justify-start'>
							<div className='font-bold flex flex-row pb-4 text-left'>
								<div className='text-2xl text-yellow-600 pr-2'>
									<FiPhoneForwarded />
								</div>
								<div className='font-bold'>Zrealizuj rezerwację</div>
							</div>
							<p className='text-left'>
								Nie martw się, że zapomnisz o wybranym terminie przypomnimy Ci w
								wiadomości sms i mailu
							</p>
						</p>

						<p className='flex items-start flex-col content-start justify-start'>
							<div className='font-bold flex flex-row pb-4 text-left'>
								<div className='text-2xl text-yellow-600 pr-2'>
									<FiThumbsUp />
								</div>
								<div className='font-bold'>Usługa jest bezpłatna</div>
							</div>
							<p className='text-left'>
								Korzystanie z serwisu Znany.pl jest bezpłatne
							</p>
						</p>
					</div>

					<div className='flex md:w-max m-auto py-8 px-5 relative'>
						<h1 className='text-center text-4xl justify-center w-full'>
							Szukam na
							<br className=' md:hidden' />
							<span className='hidden md:inline'> </span>
							znany.pl
							<Image
								className={`z-10 w-1 mb-4`}
								width='40'
								height='21'
								src={'/strzalka2.png'}
							/>
						</h1>
						{/* <div className='ml-4 -mt-4 absolute right-8  bottom-10'>
						</div> */}
					</div>

					{/*PEOPLE*/}
					<div
						className={`md:w-11/12 xl:w-10/12 px-5 md:px-0 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-8 m-18 mx-auto mb-8`}
					>
						{specialistsData?.random_specialists_v3.length &&
							specialistsData?.random_specialists_v3
								.filter((item: any, index: number) => index < 21)
								.map((el: any, i: number) => (
									<>
										<div className='mb-2' key={el.id}>
											<Link
												href={`/specjalista/${el.id}`}
												key={el.slug}
												passHref
											>
												<img
													className={`relative rounded-md z-10 block cursor-pointer`}
													src={el.avatar_url || '/face1.png'}
													style={{
														objectFit: 'contain'
													}}
													width={400}
													height={400}
												/>
											</Link>
											<Link href={`/specjalista/${el.id}`} key={el.slug}>
												<a className={`block mt-2 font-bold`}>
													{el.shown_name}
												</a>
											</Link>
											<p className='pb-3 '>{el.profession.name}</p>
											<Stars
												stars={el.opinions_aggregate.aggregate.avg.rating}
												opinions={0}
											/>
										</div>
									</>
								))}
					</div>
				</PageFullWrapper>
				<Footer home />
			</MainWrapper>
		</SessionProvider>
		</>
	);
};
export default homePage;
